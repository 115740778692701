import "animate.css"
import "./styles/global.scss"
import "./App.css"
import CryptoSwap from "./components/CryptoSwap";
import logo from "./assets/images/logo.png"
import {ConnectKitButton} from "connectkit";
import {useState} from "react";

function App() {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <main>
      <div className='air air1'></div>
      <div className='air air2'></div>
      <div className='air air3'></div>
      <div className='air air4'></div>
      <div className="header">
        <img src={logo} alt="logo" className="logo"/>
        <p>
          Mirage Foundation is running in Beta version. Support team: <a
          href="mailto:support@mirage.foundation">support@mirage.foundation</a>
        </p>
        <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
      </div>
      <CryptoSwap/>
    </main>
  );
}

export default App;
